@tailwind base;
@tailwind components;
@tailwind utilities;
html{
    @apply m-0 p-0;
}
.container{
    @apply m-auto;
}
.row{
    @apply flex flex-col md:flex-row;
}
.row-center{
    @apply flex flex-col md:flex-row items-center;
}
.col{
    @apply flex flex-col;
}
.col-center{
    @apply flex flex-col items-center;
}